html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

#fb-share-button {
  background: #3b5998;
  border-radius: 3px;
  font-weight: 600;
  padding: 5px 8px;
  display: flex;
  position: static;
  align-items: center;
  font-family: Poppins, Roboto, "Open Sans", serif;
}

#fb-share-button:hover {
  cursor: pointer;
  background: #213A6F
}

#fb-share-button svg {
  width: 18px;
  fill: white;
  vertical-align: middle;
  border-radius: 2px
}

#fb-share-button span {
  vertical-align: middle;
  color: white;
  font-size: 14px;
  padding: 0 3px
}
